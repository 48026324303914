import $ from 'jquery';
import onmount from 'onmount';
import slugify from 'slugify';

onmount('#preonboarding_page', function () {
  $('#next').on('click', goNext);
  $('#back').on('click', goBack);
  $('#new_starter_preonboarding').on('input change', updateButtons);
  $('.is-invalid').on('input change', cleanRailsInvaild);
  $('#starter_preonboarding_company_name').on('input change', updateUrlSlug);

  // Actualizar el estado de los botones dependiendo de la información ya ingresada
  updateButtons();
  // Mostrar la parte del formulario que tenga elementos inválidos
  if (firstStepValid() && !secondStepValid()) {
    goNext();
  }

  function goNext() {
    $('#step_one_panel').hide();
    $('#step_two_panel').show();
    $('#step_one_text').html('<i class="fa fa-check"></i>');
    $('#step_two_background').attr('class', 'fa fa-circle fa-stack-2x step-background');
    $('#side-image__personal').toggleClass('d-none');
    $('#side-image__company').toggleClass('d-none');
    $('#starter_preonboarding_company_name').focus();
  }

  function goBack() {
    $('#step_one_panel').show();
    $('#step_two_panel').hide();
    $('#step_one_text').html('1');
    $('#step_two_background').attr('class', 'fa fa-circle fa-stack-2x step-background-muted');
    $('#side-image__personal').toggleClass('d-none');
    $('#side-image__company').toggleClass('d-none');
  }

  function updateButtons() {
    const isFirstStepValid = firstStepValid();
    $('#next').prop('disabled', !isFirstStepValid);
    $('#continue').prop('disabled', !(isFirstStepValid && secondStepValid()));
  }

  function firstStepValid(){
    const validInputs = $('#step_one_panel :invalid, #step_one_panel .is-invalid').length === 0 ;

    return validInputs;
  }

  function secondStepValid(){
    const validInputs = $('#step_two_panel :invalid, #step_two_panel .is-invalid').length === 0;
    const validRut = $('.starter_preonboarding_company_rut.has-error').length === 0 &&
      $('#starter_preonboarding_company_rut').val() !== '';

    return validInputs && validRut;
  }

  function cleanRailsInvaild(event) {
    const invalidElement = $(event.target);
    invalidElement.removeClass('is-invalid');
    invalidElement.parent().siblings('.invalid-feedback').remove();
  }

  function updateUrlSlug(event){
    const inputValue = event.target.value.trim();
    const completeWordsValue = keepCompleteWordsBeforeLimit(inputValue);
    const slugifiedValue = slugify(completeWordsValue, { lower: true, strict: true, replacement: '' });
    const filteredValue = removeNotAllowedLastWord(slugifiedValue);
    $('#starter_preonboarding_host').val(filteredValue);
  }

  function removeNotAllowedLastWord(text){
    const notAllowedStrings = ['spa', 'ltda', 'sa', 'eirl'];
    const lastIndex = text.lastIndexOf("-");
    const lastWord = text.substring(lastIndex + 1, text.length);
    return notAllowedStrings.includes(lastWord) ? text.substring(0, lastIndex) : text;
  }

  function keepCompleteWordsBeforeLimit(text, limit = 50){
    const replaced_text = text.replaceAll('|', '').replaceAll(/&/g, '');
    if(replaced_text.length > limit){
      var trimmedString = replaced_text.substr(0, limit);
      var lastCompleteWordIndex = Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
      return trimmedString.substr(0, lastCompleteWordIndex);
    }
    return replaced_text;
  }
});
