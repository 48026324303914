import Rails from "@rails/ujs";
Rails.start();

import Turbolinks from "turbolinks";
Turbolinks.start();

import onmount from "onmount";
import $ from "jquery";
import "bootstrap";

$(document).on("load turbolinks:load", function () {
  onmount();
});
$(document).on("turbolinks:before-cache", function () {
  onmount.teardown();
});

import "../stylesheet/starter";
import "../images";
import "../javascript/starter/confirm_page";
import "../javascript/starter/preonboarding_page";
import "../javascript/tax-number-mask";
