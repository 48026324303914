import onmount from 'onmount';
import $ from 'jquery';

let intervalHandler;

onmount(
  '#starter-confirm-page',
  function (element) {
    const statusPath = window.location.pathname.split('/').slice(0, -1).join('/');
    const URL = `${window.location.origin}/${statusPath}/status`;
    const starterLink = $('#starter-link');
    const waitingDiv = $('#waiting');
    const finishedDiv = $('#finished');
    const failedDiv = $('#failed');

    intervalHandler = setInterval(function () {
      $.ajax({
        url: URL,
        error: (e) => console.error(e),
      }).done(data => handleStatusResponse(data))
    }, 1000)

    function handleStatusResponse(response) {

      switch (response.status) {
        case 'finished':
        waitingDiv.toggleClass('d-none');
        finishedDiv.toggleClass('d-none');
        clearInterval(intervalHandler);

        setInterval(function () {
          window.location = starterLink.attr('href')
        }, 5000)

        break;

        case 'failed':
        waitingDiv.toggleClass('d-none');
        failedDiv.toggleClass('d-none');
        clearInterval(intervalHandler);

        break;
      }
    }
  },
  // No olvidar limipiar intervals al desmontar el formulario
  function () {
    clearInterval(intervalHandler)
  }
)
