/** @file TODO: documentar */
import onmount from "onmount";
import "jquery.rut";
const peruValidator = require("validate-ruc");
const mexicoValidator = require("validate-rfc");
const { ColombiaValidators } = require("colombia-validators");

// [backspace, delete, tab, escape, K, enter, .]
const ALLOWED_INPUT_KEYS = [46, 8, 9, 27, 13, 75, 110, 190];
// [A, C, V]
const ALLOWED_CTRL_KEYS = [65, 67, 86];

const processKeyEvent = (e) => {
  if (
    $.inArray(e.keyCode, ALLOWED_INPUT_KEYS) !== -1 ||
    // Allow: Ctrl+[A,C,V], Command+[A,C,V]
    ($.inArray(e.keyCode, ALLOWED_CTRL_KEYS !== -1) &&
      (e.ctrlKey === true || e.metaKey === true)) ||
    // Allow: home, end, left, right, down, up
    (e.keyCode >= 35 && e.keyCode <= 40)
  ) {
    // let it happen, don't do anything
    return;
  }
  // Ensure that it is a number and stop the keypress
  if (
    (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
    (e.keyCode < 96 || e.keyCode > 105)
  ) {
    e.preventDefault();
  }
};

onmount("[data-rut]", function () {
  $(this)
    .rut({
      formatOn: "change keyup",
      validateOn: "change keyup",
      minimumLength: 6,
    })
    .on("rutInvalido", function () {
      $(this).parents(".form-group").addClass("has-error");
    })
    .on("rutValido", function () {
      $(this).parents(".form-group").removeClass("has-error");
    })
    .keydown(processKeyEvent);
});

onmount("[data-ruc]", function () {
  $(this).on("change keyup", function () {
    const isValid = peruValidator.validateRuc($(this).val());
    if (isValid) {
      $(this).parents(".form-group").removeClass("has-error");
    } else {
      $(this).parents(".form-group").addClass("has-error");
    }
  });
});

onmount("[data-nit]", function () {
  $(this).on("input", function () {
    let value = $(this).val();
    value = value.replace(/[^\d]/g, '').substr(0, 10);
    const formattedValue = value.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d?)$/, function(match, p1, p2, p3, p4) {
      let formatted = [p1, p2, p3].filter(Boolean).join(".");
      if (p4) formatted += "-" + p4;
      return formatted;
    });
    $(this).val(formattedValue);
    const validator = new ColombiaValidators();
    const isValid = validator.NIT.validate(value);
    $(this).parents(".form-group").toggleClass("has-error", !isValid);
  });
});

onmount("[data-rfc]", function () {
  $(this).on("change keyup", function () {
    const isValid = mexicoValidator($(this).val()).isValid;
    if (isValid) {
      $(this).parents(".form-group").removeClass("has-error");
    } else {
      $(this).parents(".form-group").addClass("has-error");
    }
  });
});

onmount("[data-rut-only-format]", function () {
  $(this).rut({ formatOn: "keyup" }).keydown(processKeyEvent);
});
